<template>
  <div id="overlay" v-if="overlay"></div>
  <div class="card">
    <Steps :model="items" :readonly="true"/>
  </div>
  <Card>
    <template #header>

    </template>
    <template #title>
      <div class="margin-40">

        <div class="flex justify-content-between">
          Seleccionar Ticket ({{ stats && stats.count }})
          <Dropdown v-model="selectedGame"
                    :options="games" optionLabel="label"
                    optionValue="code"
                    :onChange="loadData"
                    class="categorias"/>

        </div>
      </div>
    </template>
    <template #content>

      <div class="card">
        <div id="overlay" v-if="overlay"></div>
        <div>
          <DataTable
              :value="tickets" :lazy="true" :paginator="true" :rows="10" v-model:filters="filters" ref="dt"
              dataKey="id"
              class="p-datatable-gridlines p-datatable-striped p-datatable-sm"
              :totalRecords="stats.count" :loading="loadingTable" @page="onPage($event)" @sort="onSort($event)"
              responsiveLayout="scroll"
              v-model:selection="selectedAgencies" :selectAll="selectAll" @select-all-change="onSelectAllChange"
              @row-select="onRowSelect" @row-unselect="onRowUnselect"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              :rowsPerPageOptions="[10,20,50]" #
              currentPageReportTemplate="{first} al {last} de {totalRecords}">

            <Column field="tticket" header="Ticket" :sortable="true"
                    bodyStyle="text-align: center; width: 120px !important;">
              <template #body="{ data}">
                <Button
                    class="t-tickets"
                    type="button"
                    :style="getCancelColour(data.mmonto_aceptado)"
                    :label="data.tticket"
                    @click="OpenViewTicket(data)"
                />
              </template>
            </Column>

            <Column field="dtransaccion" header="Hora" :sortable="true" bodyStyle="text-align: center">
              <template #body="{data}">
                {{ formatTime2(data.dtransaccion) }}
              </template>
            </Column>
            <Column field="dtransaccion" header="Fecha" :sortable="true" bodyStyle="text-align: center"
                    footerStyle="text-align: right">>
              <template #body="{data}">
                {{ formatDate(data.dtransaccion) }}
              </template>
              <template #footer>
                Total:
              </template>
            </Column>
            <!--              <Column field="tproductoxsorteo" header="Sorteo" :sortable="true" bodyStyle="text-align: left"/>-->
            <Column field="mmonto_aceptado" header="Monto" :sortable="true"
                    bodyStyle="text-align: right"
                    footerStyle="text-align: right">
              <template #footer>
                {{ stats.mmonto_aceptado ? formatCurrency(Number(stats.mmonto_aceptado)) : formatCurrency(0) }}
              </template>
            </Column>
            <Column field="tcomercializador" header="Comercializador" :sortable="true" bodyStyle="text-align: left"/>
            <Column field="tdistribuidor" header="Distribuidor" :sortable="true" bodyStyle="text-align: left"/>
            <Column field="tagencia" header="Punto de Venta" :sortable="true" bodyStyle="text-align: left"/>
            <Column header="Estatus" :sortable="true" field="tstatus" bodyStyle="text-align: left;">
              <template #body="{data}">
                <div :class="statusClass(data)">
                  {{ getStatus(data) }}
                </div>
              </template>
            </Column>

          </DataTable>
        </div>
      </div>

    </template>
    <template #footer>
      <div class="grid grid-nogutter justify-content-start">
        <Button label="Anterior" @click="prevPage()" icon="pi pi-angle-left"/>
      </div>
    </template>
  </Card>

  <ViewDialog
      :show="displayTicket"
      :ticket="ticket"
      page="cancel"
      @close="closeViewTicketDialog"/>
</template>

<script>
import generalMixin from "@/mixins/generalMixin";
import TicketService from "@/service/backend/TicketService";
import ViewDialog from "@/components/Tickets/Dialogs/ViewDialog";

export default {
  name: "Step2",
  mixins: [generalMixin],
  components: {
    "ViewDialog": ViewDialog,
  },
  data() {
    return {
      selectedGame: 'lottery',
      games: [
        {label: 'Loteria', code: 'lottery'},
        {label: 'Animalitos', code: 'animal'},
      ],
      items: [
        {
          label: 'Seleccionar Agencia',
          to: '/tickets-anular-1'
        },
        {
          label: 'Seleccionar Ticket',
          to: '/tickets-anular-2'
        }
      ],
      stats: {count: 0, sum: 0},
      tickets: null,
      loadingTable: false,
      max: null,
      displayTicket: false,
      ticket: null,
      overlay: false,
    }
  },

  props: {
    agencia: String,
  },
  created() {
    this.ticketService = new TicketService();
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.lazyParams = {
        first: 0,
        rows: this.$refs.dt.rows,
        sortField: null,
        sortOrder: null,
        filters: this.filters,
        game: this.selectedGame,
        options: {
          fecha: this.getTodayDate(),
          cstatus_transaccion: 0,
          lista: null,
          tipo_lista: null,
        }
      };
      this.loadLazyData();
    },
    prevPage() {
      this.overlay=true;
      this.$router.push('/tickets-anular-1');
    },
    // nextPage() {
    //   this.$router.push('/tickets-anular-3');
    // },
    onPage(event) {
      this.loadingTable = true;
      const options = this.lazyParams.options;
      this.lazyParams = event;
      this.lazyParams.options = options;
      this.loadLazyData();
    },
    onSort(event) {
      const options = this.lazyParams.options;
      this.lazyParams = event;
      this.lazyParams.options = options;
      this.loadLazyData();
    },
    onFilter() {
      this.lazyParams.filters = this.filters;
      this.loadLazyData();
    },
    onSelectAllChange(event) {
      const selectAll = event.checked;

      if (selectAll) {
        this.agencyService.getAll().then(data => {
          this.selectAll = true;
          this.selectedAgencies = data.agencies;
        });
      } else {
        this.selectAll = false;
        this.selectedAgencies = [];
      }
    },
    onRowSelect() {
      this.selectAll = this.selectedAgencies.length === this.totalRecords
    },
    onRowUnselect() {
      this.selectAll = false;
    },
    loadLazyData(filters) {
      this.loadingTable = true;
      this.lazyParams.lista = null; // Get all products
      this.lazyParams.options = filters ? filters : this.lazyParams.options;
      this.lazyParams.options.cstatus_transaccion = 0; // 0 = VENDIDOS
      this.lazyParams.options.id_agencia = this.$router.currentRoute.value.query.id_agencia;
      this.lazyParams.game = this.selectedGame;

      if (this.$router.currentRoute.value.query.id_agencia) {
        setTimeout(() => {
          this.ticketService.getTableDataLazy(this.lazyParams)
              .then(data => {
                    this.tickets = data.rows;
                    this.stats = data.stats;
                    this.max = data.stats.max_aceptado;
                    this.loadingTable = false;
                  }
              );
        }, Math.random() * 1000 + 250);
      } else {
        this.$router.push({name: 'tickets-anular-1',});
      }
    },
    async OpenViewTicket(params) {
      params.game = this.selectedGame;
      this.overlay = true;
      this.ticket = await this.ticketService.getTicket(params);
      this.displayTicket = true;
    },
    closeViewTicketDialog() {
      this.displayTicket = false;
      this.overlay = false;
    },

  }
}
</script>

<style scoped lang="scss">

::v-deep(.categorias) {
  border-color: var(--green-banklot);
  border-width: initial;
  background-color: var(--green-banklot);

  .p-dropdown-label, .p-dropdown-trigger, .p-inputtext {
    color: var(--surface-0);
    font-weight: bold;
  }
}

</style>